/* INITIALIZERS + DEFAULTS
 * ============================== */
/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700'); */

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-size: 65%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

img {
  display: block;
  width: 100%;
  max-height: 100%;
}

h1 {
  font-weight: 800;
  margin: 0 0 15px;
  font-size: 3rem;
  text-align: center;
}

h2 {
  font-weight: 300;
  margin: 0;
  font-size: 2rem;
}

/* BASE APP
 * ============================== */
.App__header {
  background-color: #222;
  background-image: url('https://cdn.shopify.com/s/files/1/0590/7873/8097/files/rack-of-womens-clothing_3750x.jpg?v=1628002704');
  background-size: cover;
  color: white;
  height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}

.App_Footer {
  background-color: #222;
  background-image: url('https://cdn.shopify.com/s/files/1/0590/7873/8097/files/collecting-order-at-curbside-from-retail-store_3750x.jpg?v=1628523830');
  background-size: cover;
  color: white;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center center;
}

.App__nav{
  width: 100%;
  list-style: none;
}

.App__customer-actions {
  float: left;
  padding: 10px;
}

.App__title {
  padding: 80px 20px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 0 4px rgb(0 0 0 / 40%);
  font-weight: 800;
}

.App__title h2 {
  color: #ffffff;
  text-shadow: 0 0 4px rgb(0 0 0 / 40%);
  font-weight: 800;
}

.Product-wrapper {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.App__view-cart-wrapper {
  position: absolute;
  right: 10px;
}

.App__view-cart {
  font-size: 15px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
}

.button {
  /* background-color: #398cd4; */
  background: #FFA41C;
  border-color: #FF8F00;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

.button:hover,
.button:focus {
  opacity: 65%;
  box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%)
}
.Product img:hover {
  opacity: 65%;
}

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0,0,0,0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  color: #ffffff;
  cursor: default;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
}

/* CART
 * ============================== */
.Cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;
}

.Cart--open {
  transform: translateX(0);
}

.Cart__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.Cart__header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  flex: 0 0 auto;
  display: inline-block;
}

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
  padding-left: 0px;
  padding-right: 0px;
}

.Cart__footer {
  padding: 20px;
  padding-right: 0px;
  padding-left: 0px;
  flex: 0 0 auto;
  margin-bottom: 5%;
}

.Cart__footer-info{
  margin-top: 30px;
  text-align: center;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  letter-spacing: 0.3px;
  margin-right: 6px;
}

.Cart__checkout-end {
  display: flex;
  justify-content: flex-end;
  margin-top: 3%;
}
.Cart__checkout {
  margin-top: 20px;
  display: block;
  width: 200px;
}

.Cart-info {
  padding: 15px 20px 10px;
  margin-bottom: 12px;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
}

.Cart-info__small {
  font-size: 11px;
}

.Cart-info__pricing {
  float: right;
}

.pricing {
  margin-left: 5px;
  font-size: 15px;
  color: #3a3a3a;
  letter-spacing: 0.3px;
  font-weight: 600;
}

/* LINE ITEMS
 * ============================== */
.Line-item {
  padding-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  border-bottom: 1px solid #ebebeb;
}

.Line-item__img {
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e5e5e5;
  position: absolute;
}

.Line-item__content {
  width: 100%;
  padding-left: 75px;
}

.Line-item__content-row {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
}

.Line-item__variant-title {
  float: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676;
}

.Line-item__title {
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 0.7;
  color: #3a3a3a;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.Line-item__price {
  line-height: 23px;
  font-weight: bold;
  font-size: 15px;
}

.Line-item__quantity-container {
  border: 1px solid #767676;
  /* float: left; */
  border-radius: 3px;
}

.Line-item__quantity-update {
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: black;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}

/* PRODUCTS
 * ============================== */
.Product {
  width: 25%;
  padding-left: 30px;
  margin-bottom: 3%;
}

.Product img {
  height: 350px;
}
.Product__title {
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 0.7;
  color: #3a3a3a;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.Product__title:hover {
  text-decoration: underline;
  cursor: pointer;
}

.Product__price {
  display: block;
  opacity: 0.5;
  margin-bottom: 1.4rem;
  font-size: 17px!important;
  line-height: 24px!important;
  color: #B12704!important;
}

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  height: 35px;
  padding-left: 20px;
  margin-top: 15px;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

/* CUSTOMER AUTH
 * ============================== */
.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed;
}

.CustomerAuth--open {
  display: block;
}

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative;
}

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
}

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%;
}

.CustomerAuth__submit {
  float: right;
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}

.quantity label {
  font-size: 14px;
  margin-right: 5%;
}

.quantity input {
  height: 31px;
  padding-left: 10px;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
}

.quantity {
  margin-bottom: 5%;
  margin-top: 15px;
}

.buy-button {
  text-align: center;
}

.description {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 12px;
  margin-top: 10px;
}

.flex {
  display: flex
}

.flex-one {
 flex: 1;
}

.flex-three {
  flex: 3;
 }

 .flex5 {
  flex: 5;
 }
 .flex05 {
  flex: 0.5;
 }
 .margin-top-image {
   margin-top: 5%;
 }

 .home-bar {
  display: flex;
  justify-content: center;
  font-size: 15px;
  flex-wrap: wrap;
  color: #7A7E85;
  text-transform: uppercase;
  font-weight: 800;
  background: #f8f9f8;
 }

 .home-bar a {
  text-decoration: none;
  color: #7A7E85;
 }

 .home-bar-active {
  color: #7EB23D;
  border-bottom: 4px solid #7EB23D;
 }

 .home-bar div {
  padding: 20px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  display: flex;
 }

 .page-width {
  max-width: 1200px;
  margin: 0 auto;
}

.filters-toolbar-wrapper {
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  margin-bottom: 55px;
  margin-top: 55px;
}

.filter-toolbar-layout {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.filter-toolbar-layout  select {
  height: 47px;
  padding-left: 16px;
  padding-right: 15px;
  margin-left: 15px;
  border: none;
  font-size: 14px;
}

.total-product {
  font-size: 13px;
  font-weight: 500;
}

.svgIcon{
  height: 20px;
}

.medium-up--one-half {
  width: 50%;
  padding-left: 30px;
}
.medium-up--one-half-img {
  max-width: 530.0px;
  max-height: 530px;
}
.main-content {
  padding-top: 55px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.product-single__title {
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.2;
  font-weight: 800;
  margin: 0 0 15px;
  font-size: 3.5rem;
  text-align: left;
}

.selector label {
  font-size: 15px;
  color: #3a3a3a;
}

.selector select {
  border: 1px solid #cccccc;
  background-color: #ffffff;
  color: #000000;
  padding-top: 10px;
  padding-left: 18px;
  padding-bottom: 10px;
  border-radius: 2px;
  min-height: 44px;
}
.selector {
  width: 48%;
  margin-bottom: 4%;
}

.space-between {
  justify-content: space-between;
  flex-wrap: wrap;
}

.addToCart {
  display: block;
  width: 100%;
  line-height: 1.4;
  padding-left: 5px;
  padding-right: 5px;
  white-space: normal;
  margin-top: 0;
  margin-bottom: 10px;
  min-height: 44px;
  border-color: #3a3a3a;
  padding: 10px 18px;
  border-radius: 3px;
  cursor: pointer;
}
.primary-color {
 background: #3a3a3a;
 color: #ffffff;
}

.secondary-color {
  background-color: #ffffff;
  color:  #3a3a3a;
 }

.product-single__description {
    margin-top: 30px;
    color:  #3a3a3a;
    font-size: 15px;
}

.product-single__description span {
  margin-bottom: 12px;
}

.product-single__thumbnail {
  display: block;
  margin: -2px 0 8px;
  min-height: 44px;
  position: relative;
  border: 2px solid transparent;
  width: 25%;
  cursor: pointer;
}

.empty-page-content {
  padding: 125px 55px;
}
.text-center {
  text-align: center!important;
}

.arrowSVG {
  height: 9px;
  margin-left: 9px;
}

.continue-shop {
  padding: 10px 18px;
  height: 30px;
  background: #3a3a3a;
  color: #ffffff;
  font-size: 16px;
  border: none;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
}
.continue-shop:hover {
  opacity: 65%;
}

.body-text {
  color: #333232;
  font-size: 15px;
  margin-bottom: 5%;
  margin-top: 4%;
}

.featured-items {
  margin-top: 3%;
  margin-bottom: 5%;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.view-all {
  margin-bottom: 7%;
  margin-top: 5%;
  text-align: center;
}

.footer-text {
  font-size: 15px;
  color: #7A7E85;
  font-weight: 800;
  background: #f8f9f8;
  height: 250px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.footer-text a {
  text-decoration: none;
  color: #7A7E85;
}

.footer-list-layout {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
}
.footer-list-layout div {
  padding: 10px;
}
.footer-text div{
  cursor: pointer;
}
.poweredBY {
  padding-right: 2%;
}

.quick-links {
  padding-left: 10px;
  font-size: 15px;
  font-weight: 800;
  color: #3a3a3a;
}
.footer-list-layout div:hover {
  text-decoration: underline;
}
.social-icons .icon {
  width: 25px;
  height: 25px;
}

.list--inline>li {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.social-icons__item {
  padding: 0 15px;
}
.list--inline {
  margin-top: 8%;
}
li {
  list-style: none;
}
.list--inline>li span {
  display: none;
}

p {
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 2%;
}

.shopping-padding {
  padding: 65px;
}
.shopping-padding div {
  width: 50%;
  margin: auto;
}
.textCenter {
  text-align: center;
}

.chats {
  position: fixed;
  z-index: 2147483000;
  right: 2%;
  bottom: 5%;
}
.chats-whatsApp {
  width: 60px;
  height: 60px;
  margin-left: 10px;
  border-radius: 50px;
  bottom: 20px;
  right: 1%;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: none;
}
.whatsApp-icon{
  fill: white;
  width: 50px;
  height: 50px;
  padding: 3px;
  background-color: #4dc247;
  border-radius: 50%;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  position: fixed;
  bottom: 20px;
  right : 20px;
  z-index: 10;
}
.chat-with-us span {
  background-color: #fff;
  border-radius: 8px;
  line-height: 1.2;
  padding: 10px 10px;
  letter-spacing: 1px;
  box-shadow: none;
  font-family: Muli;
  font-size: 16px;
}
.chat-with-us {
  color: black;
  margin: 0 10px;
  padding: 10px 10px;
  box-shadow: none;
  font-family: Muli;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1%;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-link--accent {
  color: #3a3a3a;
  padding-bottom: 2px;
  font-size: 14px;
  letter-spacing: 1px;
  text-decoration: underline;
  cursor: pointer;
}

.cart-page-header { 
  display: flex;
  justify-content: space-between;
  padding-top: 5%;
  padding-bottom: 3%;
  color: #3a3a3a;
  letter-spacing: 0.3px;
  font-size: 13px;
  border-bottom: 1px solid #dfdfdf;
}
.cart__image {
  max-height: 95px;
  display: block;
  margin: 0 auto;
}

.cart_product_title {
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  color: #3a3a3a;
  font-weight: 800;
  overflow: hidden;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  line-height: 22px;
}

.cart_variant_title {
  color: #3a3a3a;
  font-size: 14px;
  opacity: 5;
  font-weight: 500;
}

.cart_product_padding {
  padding-left: 20px;
  padding-right: 20px;
}
.cart_product_title:hover {
  text-decoration: underline;
  cursor: pointer;
}

.center-item-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-count {
  border-radius: 50%;
  min-width: 1em;
  height: 1em;
  background: black;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 13px;
  left: 33px;
}
.loader-height {
  width: 50%;
  margin: 0px auto;
  display: flex;
  align-items: center;
}

.pagination button {
  padding: 10px 18px;
  height: 54px;
  border-radius: 3px;
  color: #383838;
  margin-right: 10px;
  background: #fff;
  cursor: pointer;
  margin-bottom: 20px;
}
.pagination button svg {
  font-size: 14px;
  height: 30px;
  width: 30px;
}
.pagination {
  margin-bottom: 5%;
  margin-top: 5%;
  text-align: center;
}

.soldOut {
  margin-top: 5%;
  font-size: 15px;
  letter-spacing: 0.5px;
  margin-bottom: 5%;
}
.red-color {
  color: red !important;
}
.cursor-not-allow {
  cursor: not-allowed
}
.index-section {
  padding-top: 55px;
  padding-bottom: 55px;
}
.quote-icon {
  display: block;
  margin: 0 auto 20px;
  height: 30px;
}
.quote-icon svg {
  height: 20px;
}
.author {
  font-size: 13px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
.text-underline { 
  text-decoration: underline;
}
.contact-us {
  min-height: 700px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.contact-us h3 {
  font-size: 15px;
  letter-spacing: 0.3px;
}
.margin-top-align {
  padding-top: 3%;
}
.capitalize {
  text-transform: capitalize;
}
.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}
.font-50 {
  font-size: 50px;
}
.font-35 {
  font-size: 35px;
}

.secured-checkout {
  background-image: url("./checkout.jpeg");
  height: 400px;
  width: 100%;
}
.feedbackText {
  text-transform: capitalize;
  font-size: 14px;
  margin-right: 10px;
  margin-left: 10px;
}
.category-not-found {
  text-align: center;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding: 12.5px 25px;
  border-radius: 12px;
  cursor: pointer;
  margin: 18px;
  min-height: 300px;
  background-image: url("./norecordfound.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.category-not-found-ui {
 min-height: 500px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.collection-name {
  line-height: 1.4;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #3a3a3a;
  margin-top: 10px;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.collection-name:hover {
  text-decoration: underline;
}
@media screen and (max-width: 40em) { 

  .font-35 {
    font-size: 30px;
   }
   .font-50 {
    font-size: 35px;
  }
  .filter-toolbar-layout {
    height: max-content;
    flex-direction: column;
    justify-content: space-between;
    align-items: unset;
  }
  .filter-toolbar-layout select {
    width: 50%;
    background: none;
  }
  .space-drop-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .shopping-padding {
    padding: 25px;
  }
  .flex5 {
    flex: 3.5;
   }
  .contact-us {
    min-height: 350px; 
  }
  .page-width {
    padding-left: 12px;
    padding-right: 12px; 
  }
  .feedback {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .Line-item__price { 
    font-size: 13px;
  }

 .flex-col-mobile {
    flex-direction: column;
  }
  .flex-two-mobile {
    flex: 2;
  }
  .flex-one-mobile {
    flex: 1;
  }
  .shopping-padding div {
    width: 100%;
  }
  .footer-text div {
    padding-right: 0px;
  }
  .footer-text {
    flex-direction: column; 
    align-items: flex-start;
  }
  .poweredBY {
    padding: 30px;
  }
  .body-text  {
    margin-bottom: 11%;
  }
  .Product {
    width: 50%;
    margin-bottom: 3%;
    padding: 20px;
  }
  .Product img {
    height: 200px;
  }

  .Cart {
    width: 100%;
  }
  .Product-wrapper {
    margin: 0px;
  }

  .display-none {
    display: none;
  }
  .App__header {
    height:400px;
    background-position: unset;
  }
  .medium-up--one-half {
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
  }
  .flex-column-mobile {
    flex-direction: column;
  }
  .product-single__title {
    font-size: 2.5rem;
  }
  .list--inline {
    padding-left:0px
  }
  .chats {
    right: 0%;
    bottom: 3%;
}
.Line-item__quantity { 
  width: 30px;
}
.Line-item__quantity-update {
  width: 20px;
}
}